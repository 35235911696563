@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300,700|Raleway:400,500,600,700,300);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  font-weight: 400;
  list-style: none;
  margin: 0;
  vertical-align: baseline; }

/*
 *	Variables
 */
html {
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px; }

body {
  background-color: #F9F9F9;
  padding-top: 75px; }

input[type="search"] {
  -webkit-appearance: none; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button, input, input[type="radio"], input[type="checkbox"], label {
  border: 0;
  font-weight: normal;
  margin: 0;
  -webkit-appearance: none; }

h2 {
  color: #e9e8e8;
  font-family: Oswald;
  font-size: 95px;
  font-weight: 500;
  line-height: 85px;
  text-align: left;
  text-transform: uppercase; }
  @media (max-width: 1300px) {
    h2 {
      font-size: 80px;
      line-height: 80px; } }
  @media (max-width: 900px) {
    h2 {
      font-size: 65px;
      line-height: 65px; } }
  @media (max-width: 500px) {
    h2 {
      font-size: 45px;
      line-height: 45px; } }

a, a:link, a:active, a:visited, a:hover {
  color: inherit;
  text-decoration: none; }

#header {
  background-color: #fff;
  height: 75px;
  overflow: visible;
  padding: 0 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999; }
  @media (max-width: 900px) {
    #header {
      padding: 0 20px; } }
  @media (max-width: 500px) {
    #header {
      padding: 0 10px; } }
  #header img {
    display: inline-block;
    vertical-align: top;
    height: auto; }
    @media (max-width: 900px) {
      #header img {
        width: 130px; } }
    @media (max-width: 500px) {
      #header img {
        width: 110px; } }
    #header img#sitelogo {
      margin-top: 13px; }
      @media (max-width: 900px) {
        #header img#sitelogo {
          margin-top: 18px; } }
      @media (max-width: 500px) {
        #header img#sitelogo {
          margin-top: 21px; } }
  #header nav {
    display: inline-block;
    float: right;
    vertical-align: top; }
    #header nav ul {
      display: inline-block;
      vertical-align: middle; }
      #header nav ul li {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
        transition: 300ms all ease; }
        #header nav ul li.active a {
          color: #1b4775; }
        #header nav ul li a {
          color: #999;
          line-height: 75px;
          font-family: 'Oswald';
          font-size: 16px;
          font-weight: 500;
          text-decoration: none;
          text-transform: uppercase; }
    #header nav ul#languages li a img {
      display: inline-block;
      margin-top: -3px;
      vertical-align: middle; }
    @media (max-width: 1300px) {
      #header nav#menu-desktop {
        display: none; } }
  #header .toggle-menu {
    display: none;
    right: 20px;
    position: fixed;
    top: 22px; }
    @media (max-width: 1300px) {
      #header .toggle-menu {
        display: inline-block;
        float: left;
        margin-left: 20px; }
        #header .toggle-menu .btn-menu {
          display: inline-block;
          width: 18px;
          height: 18px;
          cursor: pointer;
          text-decoration: none; }
        #header .toggle-menu .btn-menu.gray i {
          background: #252525;
          color: #252525; }
        #header .toggle-menu .btn-menu.gray i::before, #header .toggle-menu .btn-menu.gray i::after {
          background: #252525; }
        #header .toggle-menu .btn-menu i {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 3px;
          color: #000;
          font: bold 14px/.4 Helvetica;
          text-transform: uppercase;
          text-indent: -55px;
          background: #000;
          transition: all .2s ease-out; }
        #header .toggle-menu .btn-menu i::before, #header .toggle-menu .btn-menu i::after {
          content: '';
          width: 18px;
          height: 3px;
          background: #000;
          position: absolute;
          left: 0;
          transition: all .2s ease-out; }
        #header .toggle-menu .btn-menu i::before {
          top: -7px; }
        #header .toggle-menu .btn-menu i::after {
          bottom: -7px; }
        #header .toggle-menu .btn-menu:hover i::before {
          top: -5px; }
        #header .toggle-menu .btn-menu:hover i::after {
          bottom: -5px; }
        #header .toggle-menu .btn-menu.active i {
          background: transparent;
          color: #252525; }
        #header .toggle-menu .btn-menu.active i::before {
          top: 0;
          background: #252525;
          -webkit-transform: rotateZ(45deg);
          -moz-transform: rotateZ(45deg);
          -ms-transform: rotateZ(45deg);
          -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg); }
        #header .toggle-menu .btn-menu.active i::after {
          bottom: 0;
          background: #252525;
          -webkit-transform: rotateZ(-45deg);
          -moz-transform: rotateZ(-45deg);
          -ms-transform: rotateZ(-45deg);
          -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg); } }

#menu-mobile {
  background-color: #fff;
  display: none;
  top: -435px;
  transition-duration: .5s;
  width: 100%;
  z-index: 5; }
  @media (max-width: 1300px) {
    #menu-mobile {
      display: block;
      left: 0;
      position: fixed; }
      #menu-mobile.active {
        top: 75px;
        transform: translate3d(0px, 0, 0px); } }
  #menu-mobile ul {
    width: 100%; }
  #menu-mobile li {
    border-top: 1px solid rgba(137, 137, 137, 0.1);
    display: block;
    height: 40px;
    line-height: 40px;
    margin: 0;
    text-align: center;
    transition: 300ms all ease; }
    #menu-mobile li.active {
      background-color: #1b4775; }
      #menu-mobile li.active a {
        color: #fff; }
      #menu-mobile li.active:hover {
        background-color: #1b4775; }
    #menu-mobile li:hover {
      background-color: #f8f8f8; }
    #menu-mobile li a {
      color: #000;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      line-height: 40px;
      text-decoration: none;
      width: 100%; }
  #menu-mobile ul#languages {
    width: 56px;
    display: block;
    margin: 0 auto; }
    #menu-mobile ul#languages li {
      display: inline-block;
      border-top: 0px; }
      #menu-mobile ul#languages li:first-of-type {
        margin-right: 20px; }
      #menu-mobile ul#languages li a img {
        display: inline-block;
        margin-top: -3px;
        vertical-align: middle; }

/*
 *	Slider
 */
#home-slider {
  background-color: #192834;
  height: calc(100vh - 300px);
  min-height: 600px;
  overflow: hidden;
  position: relative;
  width: 100%; }
  @media (max-width: 900px) {
    #home-slider {
      height: 360px;
      min-height: 360px;
      padding: 0; } }
  #home-slider #slider-data {
    display: none; }
  #home-slider #slider-controls {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 40px; }
    @media (max-width: 900px) {
      #home-slider #slider-controls {
        bottom: 10px;
        top: initial;
        position: absolute;
        left: 50%;
        margin-left: -43px;
        width: 86px; } }
    #home-slider #slider-controls .slider-button {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      cursor: pointer;
      height: 16px;
      margin-bottom: 20px;
      transition-duration: .3s;
      width: 16px; }
      @media (max-width: 900px) {
        #home-slider #slider-controls .slider-button {
          display: inline-block;
          margin: 0; }
          #home-slider #slider-controls .slider-button:not(:last-of-type) {
            margin-right: 15px; } }
      #home-slider #slider-controls .slider-button.active, #home-slider #slider-controls .slider-button:hover {
        background-color: #fff;
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.7); }
  #home-slider .slide-cache {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1; }
  #home-slider #slider-content {
    z-index: 1;
    position: relative;
    float: right;
    right: 40px;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 900px) {
      #home-slider #slider-content {
        text-align: center;
        padding: 0 20px;
        width: 100%;
        right: 0px; } }
    #home-slider #slider-content.sliding {
      animation-duration: 600ms;
      animation-name: slide-content;
      animation-timing-function: ease-out; }
    #home-slider #slider-content .slide {
      height: 100%;
      width: 100%; }
      #home-slider #slider-content .slide .slide-content {
        float: right;
        width: 520px; }
        @media (max-width: 900px) {
          #home-slider #slider-content .slide .slide-content {
            float: none;
            width: 100%; } }
        #home-slider #slider-content .slide .slide-content .slide-border {
          background-color: #fff;
          float: right;
          height: 2px;
          width: 100px; }
          @media (max-width: 900px) {
            #home-slider #slider-content .slide .slide-content .slide-border {
              display: none; } }
        #home-slider #slider-content .slide .slide-content .slide-title {
          color: #fff;
          float: right;
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 50px;
          margin-top: 45px;
          text-align: right;
          width: 100%;
          font-weight: 700; }
          @media (max-width: 900px) {
            #home-slider #slider-content .slide .slide-content .slide-title {
              float: none;
              font-size: 24px;
              margin-top: 0px;
              margin-bottom: 10px;
              text-align: center;
              line-height: 30px; } }
        #home-slider #slider-content .slide .slide-content .slide-desc {
          color: #fff;
          float: right;
          font-size: 24px;
          text-align: right;
          /*font-weight: 300;*/
          font-weight: 700; }
          @media (max-width: 900px) {
            #home-slider #slider-content .slide .slide-content .slide-desc {
              float: none;
              font-size: 18px;
              text-align: center; } }
          @media (max-width: 500px) {
            #home-slider #slider-content .slide .slide-content .slide-desc {
              float: none;
              font-size: 18px; } }
  #home-slider #slider-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
    #home-slider #slider-image .previous-image, #home-slider #slider-image .next-image {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    #home-slider #slider-image .previous-image {
      transform: translate3d(0, 0, 0); }
      #home-slider #slider-image .previous-image.sliding {
        animation-duration: 600ms;
        animation-name: previous-img;
        animation-timing-function: ease-out; }
    #home-slider #slider-image .next-image {
      transform: translate3d(-100%, 0, 0); }
      #home-slider #slider-image .next-image.sliding {
        animation-duration: 600ms;
        animation-name: next-img;
        animation-timing-function: ease-out; }

@keyframes slide-content {
  0% {
    transform: translate3d(0, -50%, 0); }
  50% {
    transform: translate3d(100%, -50%, 0); }
  100% {
    transform: translate3d(0, -50%, 0); } }

@keyframes previous-img {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(100%, 0, 0); } }

@keyframes next-img {
  0% {
    transform: translate3d(-100%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

/*
 *	Expertises
 */
#expertise {
  height: auto;
  overflow: hidden;
  padding-top: 100px;
  text-align: center; }
  @media (max-width: 900px) {
    #expertise {
      height: auto; } }
  #expertise .expertise-top {
    margin: 0 auto 70px;
    width: 90%; }
    @media (max-width: 1300px) {
      #expertise .expertise-top {
        height: auto;
        margin: 0 auto 30px; } }
    #expertise .expertise-top h2 {
      display: block;
      margin: 0 auto 30px;
      text-align: center; }
      @media (max-width: 1300px) {
        #expertise .expertise-top h2 {
          width: 80%;
          text-align: center; } }
    #expertise .expertise-top #expertise-desc {
      margin: 0 auto;
      text-align: center;
      width: 900px;
      font-size: 16px; }
      @media (max-width: 1300px) {
        #expertise .expertise-top #expertise-desc {
          width: 80%; } }
      @media (max-width: 500px) {
        #expertise .expertise-top #expertise-desc {
          font-size: 14px; } }
  #expertise img {
    display: inline-block;
    max-height: 100%;
    max-width: calc(33% - 20px);
    height: auto; }
    #expertise img:nth-of-type(2) {
      margin: 0 10px; }
  @media (max-width: 900px) {
    #expertise .exp-desktop {
      display: none; } }
  #expertise .exp-mobile {
    display: none; }
    @media (max-width: 900px) {
      #expertise .exp-mobile {
        display: block;
        padding: 0px 20px; } }
    #expertise .exp-mobile img {
      display: block;
      margin: 0 auto 50px;
      max-width: 100%; }
      @media (max-width: 900px) {
        #expertise .exp-mobile img {
          margin-bottom: 30px; } }
      #expertise .exp-mobile img:nth-of-type(3) {
        margin: 0; }
        @media (max-width: 900px) {
          #expertise .exp-mobile img:nth-of-type(3) {
            margin: 0 auto; } }

/*
 *	Notre métier
 */
#our-jobs {
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 1090px; }
  @media (min-width: 1920px) {
    #our-jobs {
      width: 1140px; } }
  @media (max-width: 1300px) {
    #our-jobs {
      width: 1040px; } }
  @media (max-width: 1080px) {
    #our-jobs {
      width: 830px; } }
  @media (max-width: 900px) {
    #our-jobs {
      width: 100%;
      padding-bottom: 0px; } }
  @media (max-width: 900px) {
    #our-jobs h2 {
      text-align: center; } }
  #our-jobs .offer-box {
    background-color: #fff;
    border: 2px solid #f7f7f7;
    height: 520px;
    position: relative;
    transition: 300ms all ease-in;
    width: 380px; }
    @media (min-width: 1920px) {
      #our-jobs .offer-box {
        width: 430px; } }
    @media (max-width: 410px) {
      #our-jobs .offer-box {
        height: 400px;
        width: 290px; } }
    #our-jobs .offer-box:nth-of-type(1) {
      margin-left: 100px;
      margin-top: 70px;
      position: absolute; }
      @media (min-width: 1920px) {
        #our-jobs .offer-box:nth-of-type(1) {
          margin-left: 50px; } }
      @media (max-width: 1300px) {
        #our-jobs .offer-box:nth-of-type(1) {
          margin-left: 50px; } }
      @media (max-width: 1080px) {
        #our-jobs .offer-box:nth-of-type(1) {
          margin-left: 0px; } }
      @media (max-width: 900px) {
        #our-jobs .offer-box:nth-of-type(1) {
          display: block;
          margin: 30px auto 0px auto;
          position: relative; } }
    #our-jobs .offer-box:nth-of-type(2) {
      margin-left: 570px;
      margin-top: -80px; }
      @media (max-width: 1300px) {
        #our-jobs .offer-box:nth-of-type(2) {
          margin-left: 520px; } }
      @media (max-width: 1080px) {
        #our-jobs .offer-box:nth-of-type(2) {
          margin-left: 450px; } }
      @media (max-width: 900px) {
        #our-jobs .offer-box:nth-of-type(2) {
          display: block;
          margin: 70px auto 0px auto; } }
    #our-jobs .offer-box:nth-of-type(3) {
      left: 570px;
      top: 80px; }
      @media (max-width: 1300px) {
        #our-jobs .offer-box:nth-of-type(3) {
          left: 520px; } }
      @media (max-width: 1080px) {
        #our-jobs .offer-box:nth-of-type(3) {
          left: 450px; } }
      @media (max-width: 900px) {
        #our-jobs .offer-box:nth-of-type(3) {
          display: block;
          left: 0px;
          margin: 70px auto 0px auto;
          top: 0px; } }
    #our-jobs .offer-box .page {
      box-shadow: 0 0 30px 0 rgba(34, 31, 31, 0.1);
      height: 490px;
      padding-top: 50px;
      transition: 300ms all ease-in;
      width: 100%; }
      @media (min-width: 1920px) {
        #our-jobs .offer-box .page {
          padding-top: 60px; } }
      @media (max-width: 410px) {
        #our-jobs .offer-box .page {
          height: 370px;
          padding-top: 40px; } }
      #our-jobs .offer-box .page img.illus {
        display: block;
        height: 40px;
        margin: 0 auto 45px auto;
        width: 40px; }
        @media (max-width: 410px) {
          #our-jobs .offer-box .page img.illus {
            width: 30px;
            height: 30px;
            margin: 0 auto 25px auto; } }
      #our-jobs .offer-box .page h4 {
        color: #1b4775;
        font-family: Raleway;
        font-size: 20px;
        font-weight: 700;
        text-align: center; }
        @media (max-width: 410px) {
          #our-jobs .offer-box .page h4 {
            font-size: 16px; } }
        @media (min-width: 1920px) {
          #our-jobs .offer-box .page h4 {
            font-size: 36px;
            font-weight: 500; } }
      #our-jobs .offer-box .page .seperation {
        background-color: #c6c6c6;
        display: block;
        height: 1px;
        margin: 25px auto;
        width: 240px; }
        @media (max-width: 410px) {
          #our-jobs .offer-box .page .seperation {
            margin: 15px auto; } }
      #our-jobs .offer-box .page .desc-wrap {
        display: block;
        margin: 0 auto;
        width: 300px; }
        @media (max-width: 410px) {
          #our-jobs .offer-box .page .desc-wrap {
            width: 80%; } }
        #our-jobs .offer-box .page .desc-wrap p.title {
          color: #000000;
          font-family: Raleway;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 20px;
          text-align: left; }
          @media (max-width: 410px) {
            #our-jobs .offer-box .page .desc-wrap p.title {
              font-size: 14px; } }
        #our-jobs .offer-box .page .desc-wrap ul {
          height: 0;
          opacity: 0;
          overflow: hidden;
          margin-top: -10px;
          margin-bottom: 15px;
          padding-left: 15px;
          transition: 500ms all ease-out; }
          #our-jobs .offer-box .page .desc-wrap ul.active {
            opacity: 1; }
          #our-jobs .offer-box .page .desc-wrap ul li {
            color: #828282;
            font-family: Raleway;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            list-style: circle; }
    #our-jobs .offer-box .see-more svg {
      cursor: pointer;
      float: right;
      margin-right: 30px;
      margin-top: -18px;
      transition: 200ms all linear;
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.1); }
      #our-jobs .offer-box .see-more svg tspan {
        transition: 200ms all linear; }
    @media (max-width: 410px) {
      #our-jobs .offer-box .see-more svg {
        width: 32px;
        height: 32px;
        margin-top: -16px; } }
    #our-jobs .offer-box .see-more.active svg {
      transform: rotate(45deg); }
    #our-jobs .offer-box .see-more:hover svg tspan {
      transition: 200ms all linear;
      fill: #787878; }
    #our-jobs .offer-box.anim-box {
      height: 490px; }
      #our-jobs .offer-box.anim-box.horizontal {
        width: 490px; }
        @media (min-width: 1920px) {
          #our-jobs .offer-box.anim-box.horizontal {
            width: 540px; } }
    #our-jobs .offer-box.active {
      z-index: 2; }
    #our-jobs .offer-box.horizontal {
      height: 390px;
      width: 520px; }
      @media (min-width: 1920px) {
        #our-jobs .offer-box.horizontal {
          width: 570px; } }
      #our-jobs .offer-box.horizontal .page {
        height: 100%;
        padding-top: 60px;
        padding-left: 60px;
        width: 490px; }
        @media (min-width: 1920px) {
          #our-jobs .offer-box.horizontal .page {
            width: 540px; } }
        #our-jobs .offer-box.horizontal .page h4 {
          padding-right: 60px; }
        #our-jobs .offer-box.horizontal .page .seperation {
          margin: 25px auto 25px 60px; }
        #our-jobs .offer-box.horizontal .page .desc-wrap {
          margin: 0; }
      #our-jobs .offer-box.horizontal .see-more svg {
        margin-right: 6px;
        margin-top: -70px; }

/*
 *	Valeurs
 */
#values {
  font-size: 0;
  padding-top: 100px; }
  #values h3 {
    color: #1b4775;
    font-family: Raleway;
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 40px;
    margin-top: 70px;
    text-align: left; }
    @media (max-width: 900px) {
      #values h3 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 15px;
        margin-top: 30px; } }
    @media (max-width: 500px) {
      #values h3 {
        font-size: 20px;
        line-height: 24px; } }
  #values .content-box {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 900px) {
      #values .content-box {
        font-size: 15px;
        line-height: 22px; } }
    @media (max-width: 500px) {
      #values .content-box {
        font-size: 14px; } }
    #values .content-box img {
      height: auto;
      margin-top: 60px;
      max-width: 100%; }
  #values .showdesk {
    margin: 0 auto;
    width: 1250px; }
    #values .showdesk .right-content .content-box:nth-of-type(1) h3 {
      margin-top: 0; }
    @media (max-width: 1300px) {
      #values .showdesk {
        width: 100%; } }
    @media (max-width: 900px) {
      #values .showdesk {
        display: none; } }
    #values .showdesk .left-content, #values .showdesk .right-content {
      display: inline-block;
      vertical-align: top;
      width: 600px; }
    #values .showdesk .left-content {
      margin-right: 25px; }
      @media (max-width: 1300px) {
        #values .showdesk .left-content {
          padding-left: 40px;
          width: calc(50% - 25px); } }
    #values .showdesk .right-content {
      margin-left: 25px; }
      @media (max-width: 1300px) {
        #values .showdesk .right-content {
          padding-right: 40px;
          width: calc(50% - 25px); } }
  #values .showmobile {
    display: none;
    padding: 0 20px;
    text-align: left; }
    @media (max-width: 900px) {
      #values .showmobile {
        display: block; } }
    #values .showmobile .content-box img {
      margin-top: 30px;
      max-width: 100%; }

/*
 *	Operations
 */
#operation {
  padding-top: 100px; }
  #operation h2 {
    margin-bottom: 30px;
    text-align: center; }
    @media (max-width: 900px) {
      #operation h2 {
        margin-bottom: 15px; } }
  #operation p.subtitle {
    display: block;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    padding: 0 20px; }
    #operation p.subtitle small {
      font-size: 30px; }
    @media (max-width: 900px) {
      #operation p.subtitle {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 30px; } }
    @media (max-width: 500px) {
      #operation p.subtitle {
        font-size: 14px; } }
  #operation p.further-info {
    text-align: center;
    cursor: default;
    transition: 300ms all ease;
    opacity: 0;
    margin-bottom: 50px; }
    #operation p.further-info.active {
      opacity: 1; }
  #operation #grid-ops {
    font-size: 0;
    width: 100%; }
    #operation #grid-ops .ops-line {
      width: 100%; }
      #operation #grid-ops .ops-line .ops-action {
        color: #afafaf; }
      #operation #grid-ops .ops-line .ops-role {
        color: #595959; }
      #operation #grid-ops .ops-line:nth-of-type(odd) .ops-box {
        background-color: #fff; }
        #operation #grid-ops .ops-line:nth-of-type(odd) .ops-box:nth-of-type(2n) {
          background-color: #e7e7e7; }
        @media (max-width: 1300px) {
          #operation #grid-ops .ops-line:nth-of-type(odd) .ops-box {
            background-color: #e7e7e7; }
            #operation #grid-ops .ops-line:nth-of-type(odd) .ops-box:nth-of-type(2n) {
              background-color: #fff; } }
      #operation #grid-ops .ops-line:nth-of-type(even) .ops-box {
        background-color: #e7e7e7; }
        #operation #grid-ops .ops-line:nth-of-type(even) .ops-box:nth-of-type(2n) {
          background-color: #fff; }
    #operation #grid-ops .ops-box {
      display: inline-block;
      font-size: 16px;
      height: 550px;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 16.66%;
      min-height: 280px; }
      @media (max-width: 1300px) {
        #operation #grid-ops .ops-box {
          width: 33.33%; } }
      @media (max-width: 500px) {
        #operation #grid-ops .ops-box {
          background-color: #fff;
          width: 100%; }
          #operation #grid-ops .ops-box:nth-of-type(odd) .ops-box {
            background-color: #fff; }
            #operation #grid-ops .ops-box:nth-of-type(odd) .ops-box:nth-of-type(2n) {
              background-color: #e7e7e7; }
          #operation #grid-ops .ops-box:nth-of-type(even) .ops-box {
            background-color: #e7e7e7; }
            #operation #grid-ops .ops-box:nth-of-type(even) .ops-box:nth-of-type(2n) {
              background-color: #fff; } }
      #operation #grid-ops .ops-box .inner-box {
        position: relative;
        top: 50%;
        transform: translateY(-55%); }
        @media (max-width: 900px) {
          #operation #grid-ops .ops-box .inner-box {
            padding: 0 10px; } }
        #operation #grid-ops .ops-box .inner-box p {
          padding: 0 15px; }
      #operation #grid-ops .ops-box img {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 85%; }
      #operation #grid-ops .ops-box .atfis-logo {
        display: block;
        margin-top: 7px;
        width: 60px; }
      #operation #grid-ops .ops-box .ops-bottom {
        bottom: 20px;
        height: auto;
        position: absolute;
        width: 100%; }
      #operation #grid-ops .ops-box .ops-action {
        margin: 25px auto; }
      #operation #grid-ops .ops-box .ops-name {
        color: #000;
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 700; }
        @media (max-width: 900px) {
          #operation #grid-ops .ops-box .ops-name {
            font-size: 15px;
            line-height: 20px; } }
        @media (max-width: 500px) {
          #operation #grid-ops .ops-box .ops-name {
            font-size: 14px; } }
      #operation #grid-ops .ops-box .ops-action, #operation #grid-ops .ops-box .ops-role {
        font-size: 16px;
        font-weight: 500; }
        @media (max-width: 900px) {
          #operation #grid-ops .ops-box .ops-action, #operation #grid-ops .ops-box .ops-role {
            font-size: 15px;
            line-height: 20px; } }
        @media (max-width: 500px) {
          #operation #grid-ops .ops-box .ops-action, #operation #grid-ops .ops-box .ops-role {
            font-size: 14px; } }
      #operation #grid-ops .ops-box .ops-valeur {
        font-size: 28px;
        font-weight: 500; }
        @media (max-width: 900px) {
          #operation #grid-ops .ops-box .ops-valeur {
            font-size: 25px;
            line-height: 38px; } }
        @media (max-width: 500px) {
          #operation #grid-ops .ops-box .ops-valeur {
            font-size: 25px; } }

#wrapper-team {
  padding-top: 100px; }
  #wrapper-team h2 {
    margin: 0 auto 20px;
    padding: 0 10px;
    text-align: right;
    width: 1250px; }
    @media (max-width: 1600px) {
      #wrapper-team h2 {
        padding: 0 40px;
        width: 100%; } }
    @media (max-width: 900px) {
      #wrapper-team h2 {
        padding: 0 40px; } }
    @media (max-width: 500px) {
      #wrapper-team h2 {
        text-align: center; } }

#our-team {
  font-size: 0;
  margin: 0 auto;
  text-align: right;
  width: 1250px; }
  @media (max-width: 1600px) {
    #our-team {
      padding: 0 40px;
      width: 100%; } }
  #our-team h3 {
    border-bottom: 1px solid #dadada;
    color: #1a436e;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 40px;
    padding-bottom: 10px;
    text-align: left;
    width: 100%; }
    @media (max-width: 500px) {
      #our-team h3 {
        font-size: 20px; } }
  #our-team .team-box {
    display: inline-block;
    font-size: 20px;
    margin-left: 90px;
    position: relative;
    text-align: left;
    vertical-align: top;
    width: 185px; }
    @media (max-width: 1300px) {
      #our-team .team-box {
        font-size: 18px;
        margin: 0 10px 20px;
        width: calc(20% - 20px); } }
    @media (max-width: 900px) {
      #our-team .team-box {
        margin: 0 40px 40px;
        width: calc(40% - 70px); } }
    @media (max-width: 500px) {
      #our-team .team-box {
        display: block;
        margin: 0 auto 40px;
        width: 70%; } }
    @media (max-width: 360px) {
      #our-team .team-box {
        display: block;
        margin: 0 auto 20px;
        width: 80%; } }
    #our-team .team-box .box-hover {
      background-color: #fff;
      box-shadow: 0 0 8px 0 rgba(34, 31, 31, 0.21);
      box-sizing: content-box;
      display: none;
      font-size: 14px;
      height: 100%;
      left: -30px;
      padding: 0 30px 40px;
      position: absolute;
      top: -30px;
      width: 100%;
      z-index: 1; }
    #our-team .team-box img {
      border: 1px solid #dadada;
      display: block;
      height: auto;
      margin: 0 auto 35px;
      position: relative;
      width: 100%;
      z-index: 1; }
      @media (max-width: 900px) {
        #our-team .team-box img {
          margin: 0 auto 15px; } }
    #our-team .team-box .team-name {
      font-weight: 700;
      margin-bottom: 20px;
      position: relative;
      width: 100%;
      z-index: 1; }
      @media (max-width: 900px) {
        #our-team .team-box .team-name {
          font-size: 17px;
          margin-bottom: 10px; } }
    #our-team .team-box .team-job {
      color: #595959;
      font-weight: 500;
      position: relative;
      width: 100%;
      z-index: 1; }
      @media (max-width: 900px) {
        #our-team .team-box .team-job {
          font-size: 16px; } }
    #our-team .team-box .cv {
      background-color: #fff;
      box-shadow: 0 0 0 rgba(34, 31, 31, 0.21), 0 9px 8px rgba(34, 31, 31, 0.21);
      box-sizing: content-box;
      display: none;
      font-size: 14px;
      left: -30px;
      padding: 0 30px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 5; }
      @media (max-width: 900px) {
        #our-team .team-box .cv {
          font-size: 13px; } }
      #our-team .team-box .cv .cv-content {
        background-color: #fff;
        left: -30px;
        padding: 20px 30px;
        position: relative;
        width: calc(100% + 60px); }
        @media (max-width: 900px) {
          #our-team .team-box .cv .cv-content {
            padding: 10px 30px; } }
    #our-team .team-box.active .cv {
      display: block; }

/*
 *	Clients
 */
#our-clients {
  font-size: 0;
  margin: 0 auto;
  width: 1270px;
  padding-top: 100px; }
  @media (max-width: 1300px) {
    #our-clients {
      padding: 0 100px;
      padding-top: 100px;
      width: 100%; } }
  @media (max-width: 900px) {
    #our-clients {
      margin: 0 auto;
      padding: 0 50px;
      padding-top: 100px; } }
  @media (max-width: 500px) {
    #our-clients {
      margin: 0 auto;
      padding: 0 20px;
      padding-top: 100px; } }
  #our-clients h2 {
    margin-bottom: 70px; }
    @media (max-width: 500px) {
      #our-clients h2 {
        margin-bottom: 50px;
        text-align: center; } }
  #our-clients .client-box {
    display: inline-block;
    height: 275px;
    vertical-align: top;
    width: 50%;
    margin-bottom: 50px; }
    #our-clients .client-box:nth-last-child(-n+2) {
      margin-bottom: 0px; }
    @media (max-width: 1300px) {
      #our-clients .client-box {
        height: 175px;
        width: 100%; }
        #our-clients .client-box:not(:last-of-type) {
          margin-bottom: 80px; } }
    @media (max-width: 900px) {
      #our-clients .client-box {
        height: auto; } }
    #our-clients .client-box .client-img {
      display: inline-block;
      height: 165px;
      width: 165px; }
      @media (max-width: 900px) {
        #our-clients .client-box .client-img {
          height: 100px;
          width: 100px; } }
      @media (max-width: 500px) {
        #our-clients .client-box .client-img {
          display: block;
          margin: 0 auto 20px; } }
      #our-clients .client-box .client-img img {
        max-height: 100%;
        max-width: 100%; }
    #our-clients .client-box .client-content {
      background: url(../img/bg_client.png) no-repeat top left;
      display: inline-block;
      margin-left: 15px;
      padding: 20px 30px 0 40px;
      vertical-align: top;
      width: calc(100% - 200px); }
      @media (max-width: 900px) {
        #our-clients .client-box .client-content {
          padding: 10px 0 0 30px;
          background-size: 20px;
          width: calc(100% - 115px); } }
      @media (max-width: 500px) {
        #our-clients .client-box .client-content {
          display: block;
          margin: 0 auto;
          width: 100%; } }
    #our-clients .client-box .client-quote {
      color: #595959;
      font-size: 16px;
      font-weight: 500; }
      @media (max-width: 900px) {
        #our-clients .client-box .client-quote {
          font-size: 15px;
          line-height: 20px; } }
      @media (max-width: 500px) {
        #our-clients .client-box .client-quote {
          font-size: 14px; } }
    #our-clients .client-box .client-name {
      color: #000;
      font-size: 16px;
      font-weight: 600; }
      @media (max-width: 900px) {
        #our-clients .client-box .client-name {
          font-size: 15px;
          line-height: 20px; } }
      @media (max-width: 500px) {
        #our-clients .client-box .client-name {
          font-size: 14px; } }

/*
 *	Distinction
 */
#wrapper-distinction {
  font-size: 0;
  padding-top: 100px; }
  #wrapper-distinction #wrapper-award {
    color: #595959;
    display: inline-block;
    font-weight: 500;
    padding: 0 20px;
    width: 100%;
    text-align: center; }
    #wrapper-distinction #wrapper-award h2 {
      text-align: center; }
      @media (max-width: 900px) {
        #wrapper-distinction #wrapper-award h2 {
          font-size: 50px; } }
      @media (max-width: 500px) {
        #wrapper-distinction #wrapper-award h2 {
          font-size: 40px; } }
  #wrapper-distinction #award-background {
    display: inline-block;
    height: 700px;
    margin: 20px;
    vertical-align: top;
    width: calc(100% - 40px); }
    @media (max-width: 1300px) {
      #wrapper-distinction #award-background {
        display: block;
        height: 400px; } }
    @media (max-width: 900px) {
      #wrapper-distinction #award-background {
        height: 300px; } }
    @media (max-width: 500px) {
      #wrapper-distinction #award-background {
        height: 200px; } }

/*
 *	Contact
 */
#wrapper-contact {
  font-size: 0;
  height: 500px;
  padding-top: 100px; }
  @media (max-width: 900px) {
    #wrapper-contact {
      height: auto; } }
  #wrapper-contact #contact-map {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: calc(100% - 550px); }
    @media (max-width: 900px) {
      #wrapper-contact #contact-map {
        display: block;
        height: 400px;
        width: 100%; } }
  #wrapper-contact #contact-info {
    background-color: #03101e;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    height: 100%;
    padding: 90px 0;
    text-align: center;
    vertical-align: top;
    width: 550px; }
    @media (max-width: 900px) {
      #wrapper-contact #contact-info {
        display: block;
        font-size: 15px;
        line-height: 20px;
        width: 100%; } }
    @media (max-width: 900px) {
      #wrapper-contact #contact-info {
        ont-size: 14px; } }
    #wrapper-contact #contact-info #contact-logo {
      display: block;
      height: auto;
      margin: 0 auto 30px; }
      @media (max-width: 900px) {
        #wrapper-contact #contact-info #contact-logo {
          width: 130px; } }
      @media (max-width: 500px) {
        #wrapper-contact #contact-info #contact-logo {
          width: 110px; } }
    #wrapper-contact #contact-info #contact-adress {
      margin-bottom: 30px; }
    #wrapper-contact #contact-info #contact-mail {
      margin-bottom: 40px; }
    #wrapper-contact #contact-info #contact-linkedin {
      color: #fff; }
      #wrapper-contact #contact-info #contact-linkedin img {
        display: inline-block;
        height: auto;
        margin-right: 7px;
        vertical-align: middle; }
      #wrapper-contact #contact-info #contact-linkedin span {
        display: inline-block;
        vertical-align: middle; }

/*
 *	Preload
 */
#wrapper-preload {
  display: none; }

/*
 *	404
 */
#wrapper-404 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center; }
  #wrapper-404 h1 {
    color: #1b4775;
    font-size: 21px;
    margin-top: 20px;
    line-height: 31px; }
  #wrapper-404 a {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid #000;
    width: 230px;
    padding: 10px 0;
    margin: 30px auto 0;
    transition: 300ms all ease; }
    #wrapper-404 a:hover {
      color: #fff;
      background-color: #000; }
